// @flow strict
import React from "react";
import { getContactHref } from "../../../utils";
import { withPrefix, Link } from "gatsby";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["author"]}>
      <Link to="/pages/about">
        <img
          src={withPrefix(author.photo)}
          className={styles["author__photo"]}
          width="75"
          height="75"
          alt={author.name}
        />
      </Link>
      <div className={styles["author__info"]}>
        <p>
          If you enjoyed this post, you should follow me on{" "}
          <a
            className={styles["author__bio-twitter"]}
            href={getContactHref("twitter", author.contacts.twitter)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Twitter
          </a>
          , and{" "}
          <a
            className={styles["author__bio-twitter"]}
            href={getContactHref("twitter", author.contacts.linkedin)}
            rel="noopener noreferrer"
            target="_blank"
          >
            LinkedIn
          </a>{" "}
          for further updates and new articles.
        </p>
      </div>
    </div>
  );
};

export default Author;
