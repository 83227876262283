// @flow strict
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Post from "../components/Post";
import { useSiteMetadata } from "../hooks";
import { Link } from "gatsby";
import type { MarkdownRemark, PostContext } from "../types";

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  },
  pageContext: PostContext
};

const PostTemplate = ({ data, pageContext }: Props) => {
  const { prev, next } = pageContext;

  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const {
    title: postTitle,
    description: postDescription
  } = data.markdownRemark.frontmatter;
  const metaDescription =
    postDescription !== null ? postDescription : siteSubtitle;

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription}>
      <Post post={data.markdownRemark} next={next} prev={prev} />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        description
        tags
        title
      }
    }
  }
`;

export default PostTemplate;
