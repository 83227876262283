// @flow strict
import React from "react";
import { Link } from "gatsby";
import Author from "./Author";
import Comments from "./Comments";
import Content from "./Content";
import Meta from "./Meta";
import Tags from "./Tags";
import Pagination from "../Pagination";
import styles from "./Post.module.scss";
import type { Node } from "../../types";

type Props = {
  post: Node,
  next: Node,
  prev: Node
};

const Post = ({ post, next, prev }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date } = post.frontmatter;

  return (
    <div className={styles["post"]}>
      <Link className={styles["post__home-button"]} to="/">
        All Articles
      </Link>

      <div className={styles["post__content"]}>
        <Content body={html} title={title} />
      </div>

      <div className={styles["post__footer"]}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />

        {next && (
          <Link
            className={styles["post__next"]}
            title={`Next post: ${next.frontmatter.title}`}
            to={next.fields.slug}
          >
            {next.frontmatter.title}
          </Link>
        )}

        {prev && (
          <Link
            className={styles["post__prev"]}
            title={`Previous post: ${prev.frontmatter.title}`}
            to={prev.fields.slug}
          >
            {prev.frontmatter.title}
          </Link>
        )}
      </div>

      <div className={styles["post__comments"]}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
